import React from "react";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import {connect} from "react-redux";
import Header from "../../components/basics/header/Header";
import {Link} from "react-router-dom";
import DocumentsViewer from "../../components/documents-viewer/DocumentsViewer";
import NotFound from "../../components/NotFound";
import {getDocumentName, getLinkToDocument} from "../../utils/DocumentUtils";
import VersionFooter from "../../components/basics/version-footer/VersionFooter";

@connect(state => ({
    documents: state.documents.documents,
    locale: state.language.locale,
}))

class EulaView extends React.Component {

    render() {
        const {documents=[]} = this.props;
        let doc = documents.filter(d=>d.Type === "EULA").sort((o1,o2)=>o2.DtaModTime - o1.DtaModTime)[0];
        let link = getLinkToDocument(doc);
        let name = getDocumentName(doc);
        return (
            <div className={"eula-view"}>
                <Header text={<><Link to={"/"}><i className="fas fa-fw fa-arrow-left"/></Link>{name}</>}/>
                <ViewContainer>
                    {
                        !link &&
                            <NotFound/>
                    }
                    {
                        link &&
                            <DocumentsViewer path={link}/>
                    }

                </ViewContainer>
                <VersionFooter/>
            </div>
        )
    }

}

export default EulaView



